var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-list-container"},[_c('div',{staticClass:"media-child-part media__title"},[_c('router-link',{staticClass:"scrollObj",attrs:{"tag":"p","to":{
        path: '/news'
      }}},[_vm._v("news")]),_c('router-link',{ref:"compare",staticClass:"scrollObj",attrs:{"tag":"p","to":{
        path: '/awards'
      }}},[_vm._v("awards")])],1),_c('div',{staticClass:"media-child-part part-main-body"},[_c('div',{ref:"sb",staticClass:"news-list-container"},[_c('div',{staticClass:"news__list-inner"},_vm._l((_vm.lists),function(item,index){return _c('router-link',{key:index,staticClass:"news__item scrollObj",class:{
            pcHover: !_vm.$isMobile,
            mobileHover: _vm.$isMobile
          },attrs:{"tag":"div","to":{
            path: ("/news/" + (item.id))
          }}},[_c('div',{staticClass:"item__inner"},[_c('div',{staticClass:"news__publish"},[_vm._v(_vm._s(item.publish_at))]),_c('div',{staticClass:"item__cover"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"news__text"},[_c('p',{staticClass:"news__title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"news__summary"},[_vm._v(_vm._s(item.summary))])])])])}),1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }