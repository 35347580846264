<template>
  <div class="media-list-container">
    <div class="media-child-part media__title">
      <router-link
        tag="p"
        :to="{
          path: '/news'
        }"
        class="scrollObj"
        >news</router-link
      >
      <router-link
        tag="p"
        :to="{
          path: '/awards'
        }"
        class="scrollObj"
        ref="compare"
        >awards</router-link
      >
    </div>
    <div class="media-child-part part-main-body">
      <div class="news-list-container" ref="sb">
        <div class="news__list-inner">
          <router-link
            class="news__item scrollObj"
            v-for="(item, index) in lists"
            :key="index"
            :class="{
              pcHover: !$isMobile,
              mobileHover: $isMobile
            }"
            tag="div"
            :to="{
              path: `/news/${item.id}`
            }"
          >
            <div class="item__inner">
              <div class="news__publish">{{ item.publish_at }}</div>
              <div class="item__cover">
                <img :src="item.cover" alt="" />
              </div>
              <div class="news__text">
                <p class="news__title">
                  {{ item.title }}
                </p>
                <p class="news__summary">{{ item.summary }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "page-news-list",
  data() {
    return {
      lists: [],
      compare: 0
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      const that = this;
      that.PAGE_LOADING();
      that.getNews().then(res => {
        that.lists = res.data;
        that.$nextTick(() => {
          that.PAGE_LOADED();
          const compare = that.$refs.compare.$el;
          that.compare = compare.getBoundingClientRect();
          window.addEventListener("scroll", that.onScroll);
        });
      });
    },
    getNews() {
      return this.$request.get("/api/news/list");
    },
    onScroll() {
      const that = this;
      if (!that.$isMobile) {
        const obj = that.$refs.sb;
        const bcr = obj.getBoundingClientRect();
        const offset =
          parseInt(
            window
              .getComputedStyle(obj, null)
              .getPropertyValue("padding-bottom")
          ) +
          parseInt(
            window
              .getComputedStyle(document.querySelector(".news__item"))
              .getPropertyValue("margin-bottom")
          );

        if (bcr.bottom < that.compare.bottom + offset) {
          that.$refs.compare.$el.style.cssText = `bottom: ${that.$mobileScreenHeight -
            that.compare.bottom +
            that.compare.height -
            40 +
            (that.$mobileScreenHeight - bcr.bottom)}px`;
        } else {
          that.$refs.compare.$el.style.cssText = `bottom: ${that.$$mobileScreenHeight -
            that.compare.bottom}`;
        }
      }
    }
  }
};
</script>

<style></style>
